import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { TokenTable } from '~/components/TokensTable/TokenTable';
import { colors, borderWidths, space } from '@entur/tokens';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p>{`Border-tokens definerer tykkelse og radius på kantene til elementer. Tokensene gjør det enkelt å opprettholde konsistens i løsningen din og følge Entur brandet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@use '@entur/tokens/dist/semantic.scss' as semantic;
@use '@entur/tokens/dist/styles.scss';

.card {
    border: semantic.$stroke-accent $border-widths-default solid;
}
`}</code></pre>
    <h2 {...{
      "id": "border-bredde"
    }}>{`Border-bredde`}</h2>
    <TokenTable tokenKey="borderWidths" example={({
      value
    }) => <div style={{
      border: `${value}px solid ${colors.misc.black}`,
      width: space.extraLarge,
      height: space.extraLarge
    }} />} mdxType="TokenTable" />
    <h2 {...{
      "id": "border-radius"
    }}>{`Border-radius`}</h2>
    <TokenTable tokenKey="borderRadiuses" example={({
      value
    }) => <div style={{
      border: `${borderWidths.default}px solid ${colors.misc.black}`,
      borderRadius: value,
      width: space.extraLarge,
      height: space.extraLarge
    }} />} mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      